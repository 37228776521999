const zIndices = {
  background: '-10',
  chartGraph: '4', // side effect bar chart
  chartText: '5', // text label must be above bar chart
  chatDrawer: '34', // must be below the modal, but appear like a modal for the most part
  chatSecondaryMenu: '40', // must be above the modal, and the chatDrawer too
  documentViewer: '25', // must be above navbarContainer
  fadeOverlay: '30',
  filterBar: '30',
  labelSelectBoxMenuPopOver: '6',
  leftNavBar: '20',
  mapOverlay: '25',
  mobileTrialApplyButton: '50',
  modal: '35',
  modalSearchPanel: '40',
  navbarBackground: '10',
  navbarContainer: '20',
  searchAndFilterBar: '9',
  sidePanel: '100',
  sidePanelModal: '110', // Must be above sidePanel
  smallNegativeElement: '-1', // gave small elements a negative z-index
  tableHeader: '5',
  toast: '110', // Must be above sidePanel
  tooltip: '45',
  trialAndSiteSelector: '15',
}

export default zIndices
