// inspired by https://github.com/eugenezinovyev/react-fontawesome-svg-icon/blob/main/packages/react-fontawesome-svg-icon/src/FontAwesomeSvgIcon.tsx
import { classMerge } from '@components/utilities/classMerge'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import type { SizeProp } from '@fortawesome/fontawesome-svg-core'
import omit from 'lodash/omit'
import {
  type CSSProperties,
  type ForwardRefRenderFunction,
  type ForwardedRef,
  Fragment,
  type SVGAttributes,
  createElement,
  forwardRef,
} from 'react'

export interface FontAwesomeSvgIconProps
  extends Omit<
    SVGAttributes<SVGSVGElement>,
    'children' | 'mask' | 'transform'
  > {
  /** Bordered icon. */
  border?: boolean
  /** Class to add to the root SVG element. */
  className?: string
  /** Icon color. */
  color?: string
  /** Icon to render. */
  icon: IconDefinition
  /** Icon size */
  size?: SizeProp
  /** Style to add to the root SVG element. */
  style?: CSSProperties
  /** Render icon as symbol. Accepts boolean or string value.
   * True value generates symbol ID from prefix and icon name.
   * String value directly defines symbol ID.
   */
  symbol?: boolean | string
  /** Icon title. Uses area-labelledby attribute. */
  title?: string
}

const randomId = (): string => Math.random().toString(36).substr(2)

const buildClassName = (props: FontAwesomeSvgIconProps) =>
  // eslint-disable-next-line tailwindcss/no-custom-classname
  classMerge(
    'svg-inline--fa',
    `fa-${props.icon.iconName}`,
    props.size && `fa-${props.size}`,
    props.border && 'fa-border',
    props.className,
  )

const customProperties = [
  'icon',
  'size',
  'className',
  'title',
  'border',
  'role',
  'symbol',
]

type FontAwesomeSvgIconRenderFunction = ForwardRefRenderFunction<
  SVGSVGElement,
  FontAwesomeSvgIconProps
>
type SvgProps = SVGAttributes<SVGSVGElement> & {
  ref: ForwardedRef<SVGSVGElement>
}

const displayNoneStyle = { style: { display: 'none' } }
const renderFunction: FontAwesomeSvgIconRenderFunction = (
  props: FontAwesomeSvgIconProps,
  ref: ForwardedRef<SVGSVGElement>,
): JSX.Element | null => {
  const icon = props.icon

  if (!icon) {
    return null
  }

  const {
    icon: [width, height, , , vectorData],
  } = icon
  const ariaLabelledBy: string | undefined = props.title
    ? randomId()
    : undefined
  const svgProps: SvgProps = omit(props, customProperties) as SvgProps
  svgProps.className = buildClassName(props)
  svgProps.xmlns = 'http://www.w3.org/2000/svg'
  svgProps['aria-labelledby'] = ariaLabelledBy
  svgProps['aria-hidden'] = ariaLabelledBy ? undefined : true
  svgProps.focusable = ariaLabelledBy ? undefined : false
  svgProps.ref = ref
  svgProps.viewBox = `0 0 ${width} ${height}`
  svgProps.role = props.role ?? 'img'

  const symbol = props.symbol ?? false
  const children = createElement(
    Fragment,
    null,
    ariaLabelledBy &&
      createElement('title', { id: ariaLabelledBy }, props.title),
    createElement('path', { d: vectorData.toString(), fill: 'currentColor' }),
  )

  if (symbol) {
    svgProps.id = symbol === true ? `${icon.prefix}-${icon.iconName}` : symbol

    return createElement(
      'svg',
      displayNoneStyle,
      createElement('symbol', svgProps, children),
    )
  }

  return createElement('svg', svgProps, children)
}

/**
 * FontAwesome SVG icon component.
 * */
export const FontAwesomeSvgIcon = forwardRef<
  SVGSVGElement,
  FontAwesomeSvgIconProps
>(renderFunction)

FontAwesomeSvgIcon.displayName = 'FontAwesomeSvgIcon'
